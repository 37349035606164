import './App.scss'
import {
  About,
  Navigation,
  Projects,
  Research,
  Experience,
  Recents,
  SimpleHome,
} from './sections'
import { Toggle } from './components'

const App = () => {
  return (
    <div className="App">
      {/* <Navigation />
      <div className="main">
        <About />
        <Projects />
        <Experience />
        <Research />
        <Toggle />
        <Recents />
      </div> */}
      <SimpleHome />
    </div>
  )
}

export default App
